/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* general UI */
html{min-height:100%;}
body{
  min-height:100vh;
  /* background-image:url('assets/page-bg.png');*/

}
.ui-body{
  min-height:100vh;
}

.ngx-pagination .current{
  border-radius : 10px;
  background : #c8d538 !important;
}
select:required:invalid {
  color: #666;
}
option[value=""][disabled] {
  display: none;
}
.modal-backdrop.show {opacity:.7}

.form-passation .form-group {
	margin-bottom:1rem;
}

.gap {
  gap:5px;
}

.ui-item-list-item {
  margin-bottom:1rem;
}
.link{
  color:rgb(228, 82, 109);
  text-decoration:none;
  font-weight:bold;
}

/* header / footer */
.header-logo {
  width:8em;
  height:auto;
  padding:.5rem;
}
.header-item{
  margin-right : 1em;
}

@media screen and (max-width:768px) {
  .header-item {
    font-size :.8em;
    white-space: nowrap;
    }
}


.header-wrapper{
  background-color:#81b3a3;
}
.footer-wrapper{
  padding-bottom:1rem;
  font-size:.9em;
  padding-top:1rem;
  background-color:#81b3a3;
}

/* forms UI */
input, select,.ui-ng-select, .form-control {
  border:2px solid #c8d538;
  border-radius:25px;
}
.ui-select-highlight {
  border:2px solid #c8d538;
  border-radius:25px;
}
.ng-select .ng-select-container {
  border-radius:25px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color:#ecf0be;
}
.ng-select.ng-select-opened>.ng-select-container{
  border:none;
}
.ui-input-hint {
  font-size:.8em;
  padding-left:1em;
}
@media screen and (max-width:768px) {
  .ui-input-hint {padding-left:0;}
}
.passation-title-div{
  font-size: X-large;
  padding-bottom:1rem;
  text-align: center;;
}
.item-number-div {
  padding:.25rem;
  background-color:lightgray;
  color:white;
  font-weight:bold;
  text-align:center;
}
.ui-label-margin {
  padding-right:1rem;
}
.btn-primary{
  background-color:#e4526d;
  border:1px solid #e4526d;
  border-radius:25px;
}
.btn-primary:hover,
.btn-check:focus+.btn-primary, .btn-primary:focus{
  background-color:#a53e51;
  border:1px solid #e4526d;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color:rgb(78, 78, 78);
  border-color:rgb(78, 78, 78);
}
.btn-secondary{
  background-color:#9ca72d;
  border-color:#c8d538;
  border-radius:25px;
}
.btn-secondary:hover,
.btn-check:focus+.btn-secondary, .btn-secondary:focus{
  background-color:#9ca72d;
  border-color:#c8d538;
  border-radius:25px;
}
.btn-deconnexion {
  background-color:#478470;
  border-color:#478470;
}
.btn-deconnexion:hover,.btn-deconnexion:focus,.btn-deconnexion:active {
  background-color:#396b5b;
  border-color:#396b5b;
}

.alert-danger {
  padding:.25em;
  margin:0em;
  margin-left:1em;
  margin-right:1em;
}
*:focus-visible {
  outline: none;
}

.ct-label {
  font-size:12pt;
}





/*---*/

.passation-main-div{}


.border-item-color-1{
  background-color: #cee5ff;
}
.border-item-color-2{
  background-color: #caffd5;
}
.border-item-color-3{
  background-color: #ffd9f7;
}
.border-item-color-4{
  background-color: #ffd3d0;
}
.border-item-color-5{
  background-color: #fff5d4;
}


.item-color-1{
  background-color : #194f8d;
}
.item-color-2{
  background-color : #1a7e30;
}
.item-color-3{
  background-color : #8f2476;
}
.item-color-4{
  background-color : #b63128;
 }
.item-color-5{
  background-color : #f1ba03;
}

.passation-title-div{
  font-size: X-large;
}

.passation-item-name-div{
  width : 8rem;
  flex-shrink:0;
}

.passation-item-div{
  display :flex;
  align-items: center;
  margin-top : 5px;
  margin-bottom : 5px;
}
.passation-item-div>*{
  margin-left : 5px;
  margin-right : 5px;
}

.passation-button-div{
  padding-bottom:1rem;
  padding-top:1rem;
}
.passation-button-div .btn-primary:first-child {
  margin-right:2rem;
}

.passation-range-div-wrapper {}
@media screen and (min-width: 768px) {
  .passation-main-div {min-width:75%;}
}
.passation-range-div{
  width:4em;
  margin-right:1rem;
  text-align: center;
}

.passation-item-weight-text{
  font-size:.85em;
  text-align:center;
}

.passation-note-div{
  display : flex;
  justify-content: space-evenly;
  align-items: center;
}
.ct-slice-pie{
stroke : white;
}

.ct-series-a .ct-slice-pie{
     fill: #81b3a3;
}
.ct-series-b .ct-slice-pie{
     fill: #e4526d;
}
.ct-series-c .ct-slice-pie{
     fill: #f8cf63;
}
.ct-series-d .ct-slice-pie{
     fill: #c8d538;
}
.ct-series-e .ct-slice-pie{
     fill: lightgray;
     font-style : italic;
}



@page {
  size: A4;
  margin: 1cm;
}
@media print {
  html, body {
    width:210mm;
    min-height:297mm;
    margin:0;
    font-size:9pt;
    padding:0;
    background-image: none;
    background-color:white;
  }
  .ui-body, .ui-body-content, .ui-body-content-component {
    /*flex-grow:0 !important;
    display:block !important;*/
  }
  .result-wrapper {
    width:210mm;
    height:297mm;
    margin:0;
    padding:0;
  }
  .result-passation-active {transform: translateX(0%) !important;}
  .width-comparaison-active {width:50% !important;}
  .hidePrint {
    display:none !important;
    height:0;
    overflow:hidden;
  }
  .ct-label {
    font-size:9pt;
    text-anchor:middle;
  }

  .result-comparaison-items{
    flex-direction: row;
  }

}
/* PRINT */
 /*

@media print {
  html,body {
    font-size:9pt;
    margin:0;
    padding:0;
    background:white;
  }
  .ui-body {
  }
  .result-wrapper {
    width:21cm;
    height:29.7cm;
    position:absolute !important;
    top:0 !important;
  }

  .ui-body-content {

  }
  .hidePrint, .hidePrint * {
    visibility:hidden;
    overflow:hidden;
    height:0 !important;
    max-height:0 !important;
    display:none !important;
  }

}
*/
